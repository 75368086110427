/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

// import { Turbo } from "@hotwired/turbo-rails"
import * as Sentry from "@sentry/browser";
import { CaptureConsole as CaptureConsoleIntegration } from "@sentry/integrations";
// import { Application } from "@hotwired/stimulus"
// import { definitionsFromContext } from "@hotwired/stimulus-webpack-helpers"
// import * as bootstrap from "bootstrap"
// window.bootstrap = bootstrap
import "stylesheets/application"

// const application = Application.start()
const context = require.context("./controllers", true, /\.js$/)
// application.load(definitionsFromContext(context))
// window.Stimulus = application

window.Sentry = Sentry;

if (SENTRY_ENABLED) {
  Sentry.init({
    dsn: SENTRY_DSN,
    environment: SENTRY_ENV,
    release: process.env.npm_package_version,
    debug: false,
    integrations: [
      new CaptureConsoleIntegration({
        levels: ["error"],
      }),
    ],
  });
}